<template>
  <Dialog
    v-loading="isLoading"
    :name="name"
    width="530px"
    class="supplier"
    @close="handleBeforeClose"
  >
    <template #title>
      <div>{{ getTitle }} поставщика</div>
    </template>

    <el-form
      ref="formEditSupplier"
      label-position="top"
      :model="formEditSupplier"
      @submit.native.prevent
    >
      <el-row type="flex" align="middle" class="supplier__name">
        <div class="supplier__name-title">ИНН поставщика</div>
        <div class="supplier__name-form df ai-c">
          <el-form-item
            ref="inn"
            v-mask="'############'"
            prop="inn"
            class="supplier__name-form_input"
            :rules="rulesForSupplier"
          >
            <el-input
              v-model="formEditSupplier.inn"
              placeholder="Введите ИНН поставщика"
              :disabled="isLoading"
              size="small"
              @focus="$refs.inn.clearValidate()"
              @blur="wasSupplierEdited"
            />
          </el-form-item>
          <div class="supplier__name-verify">
            <SuccessIcon
              v-if="!isSupplierVerified"
              @icon-clicked="verifySupplier"
            />
          </div>
        </div>
      </el-row>
      <div v-if="isSupplierVerified" class="supplier__name-name">
        {{ formEditSupplier.name }}
      </div>

      <div class="supplier-phone__title">Добавление людей</div>
      <div class="supplier-phone__description">
        Введите номер телефона человека для проверки
      </div>

      <div v-if="!isMobile">
        <div
          v-for="(phone, index) in formEditSupplier.users"
          :key="phone.id"
          class="phoneRow"
        >
          <el-row type="flex" align="middle">
            <div class="phoneRow__title">{{ `Телефон ${index + 1}` }}</div>
            <el-form-item
              :ref="`phones${index}`"
              :prop="`users.${index}.phone`"
              :rules="rulesForDynamicPhone"
              class="phoneRow__item"
            >
              <el-input
                v-model="phone.phone"
                v-mask="'+7 (###) ###-##-##'"
                placeholder="Введите номер телефона"
                :disabled="isLoading"
                size="small"
                @blur="wasPhoneEdited(phone.phone, index)"
                @focus="clearValidate(`phones${index}`)"
              />
            </el-form-item>
            <div class="phoneRow__buttons">
              <CloseIcon @icon-clicked="handleRemovePhone(index)" />

              <SuccessIcon
                v-if="!isPhoneVerified(phone.id)"
                class="phoneRow__buttons-check"
                @icon-clicked="verifyPhone(index, phone)"
              />
            </div>
          </el-row>
          <div v-if="isPhoneVerified(phone.id)" class="phoneRow__name">
            {{ phone.user_name }}
          </div>
        </div>
      </div>

      <div v-else>
        <div
          v-for="(phone, index) in formEditSupplier.users"
          :key="phone.id"
          class="phoneRowMobile"
        >
          <div class="phoneRowMobile__label">Телефон {{ index + 1 }}</div>
          <div class="phoneRowMobile__content">
            <el-form-item
              :ref="`phones${index}`"
              :prop="`users.${index}.phone`"
              :rules="rulesForDynamicPhone"
              class="phoneRowMobile__content-phone"
            >
              <el-input
                v-model="phone.phone"
                v-mask="'+7 (###) ###-##-##'"
                placeholder="Введите номер телефона"
                :disabled="isLoading"
                size="large"
                @blur="wasPhoneEdited(phone.phone, index)"
                @focus="clearValidate(`phones${index}`)"
              />
            </el-form-item>

            <div class="phoneRowMobile__content-buttons">
              <CloseIcon @icon-clicked="handleRemovePhone(phone.id)" />

              <SuccessIcon
                v-if="!isPhoneVerified(phone.id)"
                class="phoneRow__buttons-check"
                @icon-clicked="verifyPhone(index, phone)"
              />
            </div>
          </div>
          <div v-if="isPhoneVerified(phone.id)" class="phoneRowMobile__name">
            {{ phone.user_name }}
          </div>
        </div>
      </div>
    </el-form>

    <div class="supplier__addPhone" @click="handleAddPhone">
      <GreenPlusIcon />
      <span class="supplier__addPhone-text">Добавить</span>
    </div>

    <template #footer>
      <div>
        <el-button
          :class="['w-200', { buttonSize: isMobile }]"
          type="warning"
          :disabled="!allPhonesVerified || !supplier.verified"
          @click="handleRequestForm"
        >
          {{ getTextBtn }}
        </el-button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { CONTROL_TABLE_SUPPLIER_EDIT_ROW } from '@/constants/dialogs'
import {
  GET_IS_LOADING_SUPPLIERS,
  SAVE_SUPPLIER,
} from '../../../../store/actions'
import { GET_IS_MOBILE, GET_TERMINAL_CURRENT_ID } from '@/store/actions'
import { mapActions, mapGetters } from 'vuex'
import CloseIcon from '@/assets/icons/CloseIconMobile'
import Dialog from '@/UI/dialog/Dialog'
import GreenPlusIcon from '@/assets/icons/GreenPlusIcon'
import SuccessIcon from '@/assets/icons/SuccessIconMobile'

import Api from '@/api'

export default {
  name: 'DialogEditSupplier',
  components: { Dialog, CloseIcon, SuccessIcon, GreenPlusIcon },
  props: { params: { type: Object, default: () => ({}) } },
  data() {
    // проверка наличия юрлица с указанным ИНН
    let validateSupplierInnExist = async (rule, value, callback) => {
      try {
        const { data } = await Api.control.exporters.checkInn({
          inn: value,
          refresh: 1,
        })

        this.temporarySupplier =
          data.short_name && data.short_name.length
            ? data.short_name
            : data.full_name
        callback()
      } catch (e) {
        callback(new Error('Указан несуществующий ИНН'))
      }
    }
    let validateSupplierInnLength = (rule, value, callback) => {
      if (value.length === 10 || value.length === 12) {
        callback()
      } else {
        callback(new Error('Неверный формат ИНН'))
      }
    }
    // проверка уникальности телефонного номера в списке
    // проверяет количество совпадающих > 1, поскольку сам валидируемый номер уже в списке
    let validateUniquePhone = (rule, value, callback) => {
      if (this.allPhones.filter(item => item.phone === value).length > 1) {
        callback(new Error('Этот номер уже добавлен'))
      } else {
        callback()
      }
    }
    // проверка наличия пользователя с таким телефоном в БД
    let validateExistingUser = async (rule, value, callback) => {
      try {
        let { data } = await Api.control.exporters.checkUser({
          phone: value.replace(/[\s()-]/g, ''),
          unload_id: this.getTerminalCurrentId,
        })

        if (data) {
          this.temporaryUser = {
            id: data.id,
            user_name: data.name || 'ФИО не указано',
          }
          callback()
        } else {
          callback(new Error('Пользователь с таким номером не зарегистрирован'))
        }
      } catch (e) {
        callback(
          new Error(
            'Пользователь с таким номером уже используется другим поставщиком',
          ),
        )
      }
    }

    return {
      name: CONTROL_TABLE_SUPPLIER_EDIT_ROW,
      formEditSupplier: {
        inn: '',
        name: '',
        users: [],
      },
      loading: false,
      // список всех полей телефонов на экране. И заполненных, и нет
      allPhones: [],
      supplier: {
        inn: null,
        id: null,
        verified: false,
      },
      rulesForSupplier: [
        {
          required: true,
          message: 'Укажите ИНН',
          trigger: 'submit',
        },
        { validator: validateSupplierInnLength, trigger: 'submit' },
        { validator: validateSupplierInnExist, trigger: 'submit' },
      ],
      rulesForDynamicPhone: [
        {
          required: true,
          message: 'Заполните телефон',
          trigger: 'submit',
        },
        {
          min: 18,
          message: 'Заполните телефон полностью',
          trigger: 'submit',
        },
        { validator: validateUniquePhone, trigger: 'submit' },
        { validator: validateExistingUser, trigger: 'submit' },
      ],
      temporaryUser: null,
      temporarySupplier: null,
    }
  },
  computed: {
    ...mapGetters({
      isShowLoader: GET_IS_LOADING_SUPPLIERS,
      isMobile: GET_IS_MOBILE,
      getTerminalCurrentId: GET_TERMINAL_CURRENT_ID,
    }),
    dialogData() {
      return this.getDialog(this.name).data || {}
    },
    // режим создания или редактирования
    isEditSupplier() {
      return Boolean(this.dialogData?.id)
    },
    isLoading() {
      return this.loading || this.isShowLoader
    },
    getTitle() {
      return this.isEditSupplier ? 'Изменение' : 'Добавление'
    },
    getTextBtn() {
      return this.isEditSupplier ? 'Изменить' : 'Добавить'
    },
    allPhonesVerified() {
      return !this.allPhones.find(item => item.verified === false)
    },
    isSupplierVerified() {
      return this.supplier.verified || false
    },
  },
  watch: {
    dialogData() {
      this.setFormFields()

      if (this.isEditSupplier) {
        const defaultPhonesLocal =
          this.dialogData.phones.length > 0
            ? JSON.parse(JSON.stringify(this.dialogData.phones))
            : []

        // все телефоны, которые пришли с бэка, естественно, проверены
        this.allPhones = defaultPhonesLocal.map(item => ({
          ...item,
          verified: true,
        }))
        // ИНН поставщика - аналогично
        this.supplier = {
          inn: this.dialogData.inn,
          id: this.dialogData.id,
          verified: true,
        }

        this.setFormFields(
          this.dialogData.inn,
          this.dialogData.name,
          defaultPhonesLocal,
        )
      }
    },
  },
  methods: {
    ...mapActions({
      saveSupplier: SAVE_SUPPLIER,
    }),

    // при уходе с инпута поставщика проверяем, изменен ли ИНН, и если да,
    // то меняем параметр необходимости верификации через БД
    wasSupplierEdited() {
      if (String(this.supplier.inn) !== String(this.formEditSupplier.inn)) {
        this.supplier.inn = this.formEditSupplier.inn
        this.supplier.verified = false
      }
    },

    // при уходе с инпута телефона проверяем, изменен ли телефон, и если да,
    // то меняем параметр необходимости верификации через БД
    wasPhoneEdited(phone, index) {
      if (this.allPhones[index].phone !== phone) {
        this.allPhones[index].phone = phone
        this.allPhones[index].verified = false
      }
    },

    // индикатор отображения кнопки верификации телефона
    isPhoneVerified(id) {
      return this.allPhones.find(item => item.id === id).verified
    },

    // добавление пустого поля под новый телефон
    handleAddPhone() {
      const id = Date.now()

      this.formEditSupplier.users.push({
        id,
        phone: '',
      })
      this.allPhones.push({
        id,
        phone: '',
        verified: false,
      })
    },

    // удаление телефона или незаполненного элемента
    handleRemovePhone(index) {
      this.$confirm(
        'Вы действительно хотите удалить номер?',
        'Подтверждение операции',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          type: 'warning',
        },
      )
        .then(() => {
          this.allPhones.splice(index, 1)
          this.formEditSupplier.users.splice(index, 1)
        })
        .catch(() => {})
    },
    // проверка наличия предприятия по указанному ИНН
    // и сохранение данных, если таковое есть
    verifySupplier() {
      this.$refs.formEditSupplier.validateField('inn', errorMessage => {
        if (!errorMessage) {
          this.formEditSupplier.name = this.temporarySupplier
          this.supplier.verified = true
        }
      })
    },

    // проверка наличия введенного номера телефона в БД
    // и сохранение данных, если он есть
    verifyPhone(index) {
      this.$refs.formEditSupplier.validateField(
        `users.${index}.phone`,
        errorMessage => {
          if (!errorMessage) {
            this.formEditSupplier.users[index].id = this.temporaryUser.id
            this.formEditSupplier.users[index].user_name =
              this.temporaryUser.user_name
            this.allPhones[index].id = this.temporaryUser.id
            this.allPhones[index].verified = true
          }
        },
      )
    },

    // отправка формы с данными поставщика
    handleRequestForm() {
      let message = this.isEditSupplier
        ? 'Вы действительно хотите изменить данные поставщика?'
        : 'Вы действительно хотите добавить поставщика?'

      this.$confirm(message, 'Подтверждение операции', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(() => {
          // поскольку все поля формы верифицируются в процессе заполнения,
          // дополнительная валидация перед отправкой не нужна
          const callback = this.onSuccess
          const requestData = {
            name: this.formEditSupplier.name,
            inn: this.formEditSupplier.inn,
            add_phones: this.formEditSupplier.users.map(item =>
              item.phone.replace(/[\s()+-]/g, ''),
            ),
            exporter_id: this.dialogData.exporterId,
          }

          if (this.isEditSupplier) {
            requestData.id = this.supplier.id
          }

          this.saveSupplier({ requestData, callback, params: this.params })
        })
        .catch(() => {})
    },

    // очистка данных перед закрытием формы
    handleBeforeClose() {
      this.setFormFields()
      this.$refs.formEditSupplier.clearValidate()
      this.supplier = {
        inn: null,
        id: null,
        verified: false,
      }
      this.allPhones = []
    },

    onSuccess() {
      this.handleBeforeClose()
      this.setDialog({ name: this.name })
    },

    // заполнение/очистка данных формы
    setFormFields(inn = '', name = '', users = []) {
      this.formEditSupplier.inn = inn
      this.formEditSupplier.name = name
      this.formEditSupplier.users = users
    },

    clearValidate(field) {
      this.$refs[field][0].clearValidate()
    },
  },
}
</script>

<style lang="sass" scoped>
.supplier
  &__name
    display: flex
    align-items: center
    @media(max-width: 1199px)
      flex-direction: column
      align-items: flex-start

    &-title
      width: 120px
      @media(max-width: 1199px)
        margin-bottom: 5px
    &-form
      flex: 1
      @media(max-width: 1199px)
        flex: 0
        width: 100%
      &_input
        flex: 1
        margin-bottom: 0 !important
    &-verify
      width: 60px
      text-align: right
    &-name
      margin: 6px 0 0 120px
      font-size: 16px
      line-height: 24px
      font-weight: 500
      @media(max-width: 1199px)
        margin-left: 0

  &-phone__title
    margin: 36px 0 6px
    font-size: 16px
    line-height: 24px
    font-weight: 500
    color: $grey-font

  &-phone__description
    margin-bottom: 20px
    font-size: 14px
    line-height: 22px
    color: $main-font

  .phoneRow
    position: relative
    margin-bottom: 36px
    &__title
      width: 100px
    &__item
      margin-bottom: 0 !important
      width: 300px
    &__buttons
      flex: 1
      display: flex
      flex-direction: row-reverse
      align-items: center
      margin-left: 18px
      svg
        cursor: pointer
      &-check
        margin-right: 20px
    &__name
      position: absolute
      margin: 6px 0 0 100px
      font-weight: 500
      color: $grey-font

  .phoneRowMobile
    position: relative
    margin-bottom: 36px
    &__label
      margin-bottom: 5px
    &__content
      display: flex
      align-items: center
      &-phone
        flex: 1
        margin-bottom: 0 !important
      &-buttons
        flex-shrink: 0
        display: flex
        flex-direction: row-reverse
        align-items: center
        width: 70px
        margin-left: 20px
        &_check
          margin-right: 16px
    &__name
      position: absolute
      margin-top: 6px
      font-weight: 500
      color: $grey-font

  &__addPhone
    display: flex
    align-items: center
    width: fit-content
    color: $color-green
    cursor: pointer
    &-text
      margin-left: 4px
</style>
